import './index.scss'
$("header").addClass("head1")
$(".hamburger").click(function(){
    $(this).toggleClass("active");
    $(".mob-nav").toggleClass("active");
})
$(".search").click(function(){
    $(".pc-header").hide();
    $(".search-box").css("display","flex");
})
$(".search-out").click(function(){
    $(".pc-header").show();
    $(".search-box").hide();
})
$(".nav li").hover(function(){
    $(".header-bg").show()
    $(this).find(".navchild").css("display","flex");
},function(){
    $(".header-bg").hide()
    $(this).find(".navchild").css("display","none");
})
$(".nav li:first").hover(function(){
    $(".header-bg").hide()
})

$(".mob-nav .nLi").click(function() {
    $(this).find(".sub").slideToggle();
    $(this).toggleClass("active");
})